import axios from "axios";
import { Company } from "../Types/Company"

// 会社一覧
export const getCompanies = async () => {
    const { data } = await axios.get<Company[]>('/api/admins/companies');
    return data
}

// 会社詳細
export const fetchCompanyDetail = async (): Promise<Company | null> => {
    try {
        const response = await axios.get<Company>('/api/users/company/detail');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};
// 会社編集
export const getCompanyEdit = async () => {
    try {
        const response = await axios.get<Company[]>('/api/users/company/edit');
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// 会社更新機能
export const updateCompany = async (companyData: any) => {
    try {
        // 変数が未定義の場合はデフォルト値を設定する
        const startHour = companyData.start?.hour ?? '00';
        const startMinute = companyData.start?.minute ?? '00';
        const finishHour = companyData.finish?.hour ?? '00';
        const finishMinute = companyData.finish?.minute ?? '00';
        const breakHour = companyData.breakTime?.hour ?? '00';
        const breakMinute = companyData.breakTime?.minute ?? '00';

        // 時刻データのフォーマット処理
        const start_at = `${startHour.padStart(2, '0')}:${startMinute.padStart(2, '0')}:00`;
        const finish_at = `${finishHour.padStart(2, '0')}:${finishMinute.padStart(2, '0')}:00`;
        const break_at = `${breakHour.padStart(2, '0')}:${breakMinute.padStart(2, '0')}:00`;

        // フォーマットされた時刻を含む新しいオブジェクトを作成
        const formattedData = {
            ...companyData,
            start_at,
            finish_at,
            break_at,
        };

        // APIリクエストの送信
        const response = await axios.put('/api/users/company/update', formattedData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}



// 年一覧
export const yearsSinceCreation = async () => {
    try {
        const response = await axios.get<number[]>('/api/users/company/years-since-creation');
        return response.data.years;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
// 年月一覧
export const yearMonthSinceCreation = async () => {
    try {
        const response = await axios.get<string[]>('/api/users/company/year-month-since-creation');
        return response.data;  // yearMonthsはAPIのレスポンスの想定のキーです
    } catch (error) {
        console.error(error);
        throw error;
    }
}
// 会社: 評価表表示
export const getEvaluationTableView = async () => {
    try {
        const response = await axios.get<boolean>('/api/users/company/evaluation-table/show');
        return response.data.is_show_evaluation_table;
    } catch (error) {
        console.error(error);
        return null;
    }
}
// 会社: 評価表の表示・非表示の更新
export const updateEvaluationTableVisibility = async (is_show_evaluation_table: boolean) => {
    try {
        const response = await axios.put('/api/users/company/evaluation-table/update-visibility', { is_show_evaluation_table });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
