import { AxiosError } from "axios";
import * as api from "../Api/CompanyAPI";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const handleSuccess = (message: string) => {
  toast.success(message);
};

const handleError = (error: AxiosError, defaultMessage: string) => {
  if (error.response?.data.errors) {
    Object.values(error.response?.data.errors).forEach((messages: any) => {
      messages.forEach((message: string) => {
        toast.error(message);
      });
    });
  } else {
    toast.error(defaultMessage);
  }
};

export const useCompanies = () => {
  return useQuery("compnanies", api.getCompanies);
};
export const useCompanyDetail = () => {
  return useQuery("companyDetail", api.fetchCompanyDetail);
};

export const useCompanyEdit = () => {
  return useQuery("companyEdit", api.getCompanyEdit);
};

export const useUpdateCompany = () => {
  const queryClient = useQueryClient();
  return useMutation(api.updateCompany, {
    onSuccess: (data, variables) => {
      // API呼び出し
      api.updateCompany(variables);

      queryClient.resetQueries();
      // queryClient.invalidateQueries('companies');
      handleSuccess("会社情報の更新に成功しました。");
    },
    onError: (error) => handleError(error, "会社情報の更新に失敗しました。"),
  });
};

export const useFetchYearsSinceCreation = () => {
  return useQuery("yearsSinceCreation", api.yearsSinceCreation, {
    onError: (error) => handleError(error, "年一覧の取得に失敗しました。"),
  });
};

export const useYearMonthSinceCreation = () => {
  return useQuery("yearMonthSinceCreation", api.yearMonthSinceCreation, {
    onError: (error) => handleError(error, "年月一覧の取得に失敗しました。"),
  });
};

export const useEvaluationTableView = () => {
  return useQuery("evaluationTableView", api.getEvaluationTableView);
};

export const useUpdateEvaluationTableVisibility = () => {
  return useMutation(api.updateEvaluationTableVisibility, {});
};
