import axios from "axios";
import { User } from "../Types/User"
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase";

const BASE_URL = '/api/users/user';

// 担当者一覧
export const getChargeList = async () => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/charges`);
    return convertKeysToCamelCase(data);
};

// 会社と紐づくユーザー一覧
export const getUserList = async () => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/list`);
    return convertKeysToCamelCase(data);
};

// 特定の職種IDを持つユーザー一覧
export const getUsersByOccupationId = async (occupationId: number) => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/occupation/${occupationId}`);
    return data;
};

// アシスタント一覧
export const getAssistantList = async () => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/assistant-list`);
    return data;
};

// 管理者・教育担当者一覧
export const getEducatorList = async () => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/educator-list`);
    return data;
};

// ユーザー詳細
export const getUserDetail = async (id: number) => {
    const { data } = await axios.get(`${BASE_URL}/detail/${id}`);
    // return convertKeysToCamelCase(data.data);
    return convertKeysToCamelCase(data);
};

// プロフィール
export const getUserProfile = async () => {
    const { data } = await axios.get<User>(`${BASE_URL}/profile/`);
    return convertKeysToCamelCase(data);
};

// ユーザーの職業情報を取得
export const getUserOccupation = async (id: number) => {
    const { data } = await axios.get<{occupation_id: number, occupation_label: string}>(`${BASE_URL}/detail/${id}/occupation`);
    return data;
};

// 更新機能
export const updateUser = async (user: User): Promise<void> => {
    const { shop, occupation, rank, employmentStatus, isKintaiAccount, ...userData } = user;
    try {
        await axios.put(`${BASE_URL}/update/${user.id}`, userData);
    } catch (error) {
        // エラーを throw し、エラーメッセージの表示は UserQuery.ts に委ねる
        throw error;
    }
};

// 勤怠更新機能
export const updateKintaiAccount = async (userId: string, email: string) => {
    const response = await axios.put(`/api/users/user/update/kintai/${userId}`, { email });
    return response.data;
}


// パスワード更新機能
export const updateUserPassword = async (data: { id: number; password: string; passwordConfirmation: string }): Promise<any> => {
    try {
        await axios.put(`${BASE_URL}/update-password/${data.id}`, {
            password: data.password,
            password_confirmation: data.passwordConfirmation
        });
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            // バリデーションエラーメッセージを含むエラーを投げる
            throw new Error(error.response.data.message || "パスワード更新中にエラーが発生しました");
        } else {
            throw new Error("ネットワークエラーまたは未知のエラーが発生しました");
        }
    }
};
// ユーザー追加
export const createUser = async (user: User): Promise<void> => {
    await axios.post(`${BASE_URL}/add`, user);
};

// ユーザー追加（休日付き）
export const createUserWithHoliday = async (user: User): Promise<void> => {
    await axios.post(`${BASE_URL}/add-with-holiday`, user);
};

// 削除機能
export const deleteUser = async (id: number): Promise<void> => {
    await axios.delete(`${BASE_URL}/delete/${id}`);
};

// ユーザー追加（勤怠用アカウント）
export const createKintaiAccount = async (email: string, password: string): Promise<void> => {
    await axios.post(`${BASE_URL}/create/kintai-account`, {
        email,
        password
    });
};

/**
 * セレクト系
 */
export const fetchUsersForSelect = async () => {
    const { data } = await axios.get<User[]>(`${BASE_URL}/list/select`);
    return data;
}


export const fetchUserAuthority = async () => {
    const { data } = await axios.get<{ authority: string; shopId: number }>(`${BASE_URL}/authority`);
    return data;
}
