import * as api from "../Api/UserAPI";
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// 担当者一覧
export const useChargeList = () => {
  return useQuery("charges", async () => api.getChargeList());
};
// ユーザー一覧
export const useUserList = () => {
  return useQuery("users", async () => api.getUserList());
};

// 特定の職種IDを持つユーザー一覧
export const useUsersByOccupationId = (occupationId: number) => {
  return useQuery(
    ["users", "occupation", occupationId],
    async () => api.getUsersByOccupationId(occupationId),
    {
      enabled: !!occupationId,
    }
  );
};

// アシスタント一覧: assistantList
export const useAssistantList = () => {
  return useQuery("assistants", async () => api.getAssistantList());
};

// 管理者・教育担当者一覧: educatorList
export const useEducatorList = () => {
  return useQuery("educators", async () => api.getEducatorList());
};

// ユーザー詳細
export const useUserDetail = (id: number) => {
  return useQuery(["user", id], () => api.getUserDetail(id), {
    enabled: !!id,
  });
};

// ユーザープロフィール
export const useUserProfile = () => {
  return useQuery(["user"], () => api.getUserProfile());
};

// ユーザーの職業情報を取得するカスタムフック
export const useUserOccupation = (id: number) => {
  return useQuery(["userOccupation", id], () => api.getUserOccupation(id), {
    enabled: !!id, // IDが存在する場合のみクエリを有効化
  });
};

// ユーザー追加
export const useCreateUser = () => {
  return useMutation(api.createUser, {
    onSuccess: () => {
      toast.success("新規ユーザーが追加されました！");
    },
    onError: (error: any) => {
      toast.error(`エラーメッセージ: ${error.message}`);
    },
  });
};

// ユーザー追加（休日付き）
export const useCreateUserWithHoliday = () => {
  const queryClient = useQueryClient();
  return useMutation(api.createUserWithHoliday, {
    onSuccess: () => {
      queryClient.resetQueries();
      toast.success("新規ユーザーが追加されました！");
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(`エラーメッセージ: ${errorMessage}`);
    },
  });
};

// 勤怠用アカウント追加
export const useCreateKintaiAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ email, password }) => api.createKintaiAccount(email, password),
    {
      onSuccess: () => {
        queryClient.resetQueries();
        toast.success("勤怠用アカウントが追加されました。");
      },
      onError: (error: AxiosError<any>) => {
        if (error.response?.status === 422) {
          const errorMessage = error.response?.data?.errors?.email[0];
          toast.error(`エラーメッセージ: ${errorMessage}`);
        } else {
          toast.error("エラーが発生しました。");
        }
      },
    }
  );
};

// ユーザー更新
export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(api.updateUser, {
    onSuccess: () => {
      queryClient.resetQueries();
      toast.success("スタッフの更新に成功しました。");
    },
    onError: (error: any) => {
      if (error.response && error.response.data.errors) {
        const validationErrors = Object.values(
          error.response.data.errors
        ).flat();
        toast.error(validationErrors.join("\n"));
      } else {
        toast.error(`エラーメッセージ: ${error.message}`);
      }
    },
  });
};

// 勤怠更新機能
export const useUpdateKintaiAccountQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { userId: string; email: string }) =>
      api.updateKintaiAccount(data.userId, data.email),
    {
      onSuccess: () => {
        queryClient.refetchQueries();
        toast.success("スタッフの更新に成功しました。");
      },
      onError: () => {
        toast.error("スタッフの更新に失敗しました。");
      },
    }
  );
};

// ユーザーパスワード更新
export const useUpdateUserPassword = () => {
  return useMutation(api.updateUserPassword, {
    onSuccess: () => {
      toast.success("パスワードが更新されました。");
    },
    onError: (error: any) => {
      // エラーメッセージを表示
      toast.error(`エラーメッセージ: ${error.message}`);
    },
  });
};

// ユーザー削除
export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(api.deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      navigate("/hc-user/staff/");
      toast.success("ユーザーの削除に成功しました。");
    },
    onError: (error: any) => {
      toast.error(`エラーメッセージ: ${error.message}`);
    },
  });
};

/**
 * セレクト系
 */
export const useUsersForSelectQuery = () => {
  return useQuery("usersForSelect", async () => api.fetchUsersForSelect());
};
export const useUserAuthorityQuery = () => {
  return useQuery("userAuthority", async () => api.fetchUserAuthority());
};
