import React from 'react'; // Reactをインポート
// import Audio from 'react-loader-spinner';
import { RotatingLines } from 'react-loader-spinner';

const LoaderComponent: React.VFC = () => (
    // <Audio
    //     height="80"
    //     width="80"
    //     radius="9"
    //     color="#1EC9A9"
    //     ariaLabel="loading"
    //     // wrapperClass="specificClassName"
    // />
    // <InfinitySpin
    //     width='200'
    //     color="#1EC9A9"
    // />
    <div className="p-loading">
        <RotatingLines
            strokeColor="#1EC9A9"
            strokeWidth="5"
            animationDuration="0.75"
            width="40"
            visible={true}
        />
    </div>
    // <Loader
    //     type="Puff"
    //     color="#00BFFF"
    //     height={100}
    //     width={100}
    //     timeout={3000} //3 secs
    // />
);

export default LoaderComponent;
