// /Users/nakazawakan/Desktop/hairculum-app/backend/resources/js/axiosConfig.js

import axios from 'axios';
import { toast } from 'react-toastify';

/**
 * Configure Axios
 */
axios.defaults.baseURL = '/'; // Adjust base URL if necessary

// Response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      // Handle 403 errors
      toast.error('権限が付与されていないため、この操作を実行できません。');
      window.location.href = '/no-authority';
    }
    return Promise.reject(error);
  }
);
