import React from 'react';
import { toast } from "react-toastify";
import { useQueryClient, UseMutationResult } from 'react-query';

interface ConfirmDeleteModalProps {
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    selectedId: number | null;
    // handleMasterModalClose: () => void;
    handleMasterModalClose?: () => void;
    deleteMutation: UseMutationResult<any, unknown, number, unknown>;
    onSuccess?: () => void;
}

const ConfirmDeleteModal: React.VFC<ConfirmDeleteModalProps> = (
    { title, isOpen, handleClose, selectedId, handleMasterModalClose, deleteMutation, onSuccess }
) => {
    const queryClient = useQueryClient();

    // 削除
    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (selectedId == null) {
            toast.error('IDが選択されていません。管理者へお問い合わせください。');
        }
        deleteMutation.mutate(
            Number(selectedId),
            {
                onSuccess: () => {
                    queryClient.resetQueries();
                    handleClose();
                    if (handleMasterModalClose) { // 存在する場合にのみ呼び出す
                        handleMasterModalClose();
                    }

                    if (onSuccess) { // 存在する場合にのみ呼び出す
                        onSuccess();
                    }
                },
                onError: (error) => {
                    toast.error('予期せぬエラーが発生しました。管理者へお問い合わせください。');
                }
            }
        );
    };

    const handleCloseBtnClick = () => {
        handleClose();
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className="p-modal">
            <div className="p-modal__content">
                <button
                    className="p-modal__delete"
                    onClick={handleCloseBtnClick}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">{title}</h3>
                <p className="p-modal__txt">本当に削除してもよろしいでしょうか？</p>
                <div className="p-modal__btn">
                    <button
                        className="c-btn c-btn__bgGray c-btn__min u-mr10"
                        onClick={handleCloseBtnClick}
                    >キャンセル</button>
                    <button
                        className="c-btn c-btn__bgRed c-btn__min"
                        onClick={handleDelete}
                    >削除する</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeleteModal;
